import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import CampaignCard from "../components/CampaignCard";
import API from "../api";
import FlatList from "flatlist-react";
import { getDateFromTimeStamp } from "../commonView/Helpers";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import COLOR from "../assets/js/Color";
import Loader from "../components/Loader";
import NoDataCard from "../components/NoDataCard";

let api = new API();

export default function Home() {
  const navigate = useNavigate();
  const [campaignList, setcampaignList] = useState(null);
  const [searchInput, setsearchInput] = useState("");
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    getAllCurrentCampaigns();
  }, []);
  function onCampaignClick(campaignId) {
    navigate("campaignDetail", {
      state: campaignId,
    });
  }

  function getAllCurrentCampaigns() {
    api
      .getAllCurrentCampaigns()
      .then((res) => {
        if (res.data.status === 200) {
          setcampaignList(res.data.response);
          setisLoading(false);
        } else if (res.status === 400) {
          setisLoading(false);
          console.log(res);
        } else {
          setisLoading(false);
          console.log(res);
        }
      })
      .catch((error) => {
        setisLoading(false);
        console.log(error);
      });
  }

  function clearInput() {
    setsearchInput("");
    getAllCurrentCampaigns();
  }

  const RenderCampaignCard = (item) => {
    return (
      <Grid lg={4} md={4} item key={item}>
        <CampaignCard
          onClick={() => {
            onCampaignClick(item);
          }}
          title={item.trading.name}
          address={item.trading.address}
          image={item.trading?.images[0]?.image}
          startTime={getDateFromTimeStamp(item.startTime, "MMM DD, YYYY ")}
          endTime={getDateFromTimeStamp(item.endTime, "MMM DD, YYYY ")}
          currentVisits={item.currentVisits.toLocaleString()}
          goal={item.goal.toLocaleString()}
          completedPercentage={parseInt(item.currentStatus)}
        />
      </Grid>
    );
  };

  const RenderWhenEmpty = () => {
    if (campaignList?.length === 0) {
      return <NoDataCard title="No Goalprizes Found!" />;
    }
    return <Loader visible={isLoading} />;
  };

  const searchCampaigns = () => {
    let result = campaignList.filter((o) =>
      o.trading?.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setcampaignList(result);
  };

  const SearchBar = () => {
    return (
      <>
        <Grid
          container
          direction={"row"}
          alignItems="center"
          justifyContent="center"
          columnSpacing={2}
          rowSpacing={2}
          sx={{
            marginTop: {
              xl: 1,
              lg: 1,
              md: 0,
              sm: 0,
              xs: 0,
            },
            marginBottom: {
              xl: 4,
              lg: 4,
              md: 4,
              sm: 4,
              xs: 0,
            },
          }}
        >
          <Grid item>
            <TextField
              key={"search"}
              id="outlined-name"
              label="Search for Goalprizes"
              defaultValue={searchInput}
              onChange={(e) => setsearchInput(e.target.value)}
              autoFocus="autoFocus"
              size="small"
            />
          </Grid>
          <Grid item>
            <Button
              startIcon={<SearchIcon />}
              onClick={searchCampaigns}
              variant="contained"
              size="medium"
              sx={{ backgroundColor: COLOR.GP_BLUE }}
            >
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<ClearIcon />}
              onClick={clearInput}
              size="medium"
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Box style={{ margin: 20 }}>
        <Typography align={"center"} sx={styles.titleText}>
          Find Goalprizes
        </Typography>
        <SearchBar />
      </Box>
      <Grid
        container
        direction={"column"}
        columnSpacing={3}
        rowSpacing={3}
        alignItems="center"
        justifyContent="center"
      >
        <FlatList
          list={campaignList}
          renderItem={RenderCampaignCard}
          renderWhenEmpty={RenderWhenEmpty}
        />
      </Grid>
    </>
  );
}

const styles = {
  titleText: {
    fontSize: {
      lg: 32,
      md: 32,
      sm: 32,
      xs: 24,
    },
    fontFamily: "Helvetica Neue",
    color: "black",
  },
};
