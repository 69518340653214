import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import COLOR from "../assets/js/Color";
import Box from "@mui/material/Box";
import CircularProgress from "../components/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";
import { getDateFromTimeStamp } from "../commonView/Helpers";

const FirstCard = (props) => {
  return (
    <Grid
      container
      direction={"row"}
      style={{
        backgroundColor: "black",
        borderRadius: 10,
        paddingBottom: 10,
      }}
    >
      <Grid item style={styles.cardTitileTextBox}>
        <Typography sx={styles.innerCardTitleText}>
          <strong>1. Goal: You can help the restaurant hit it</strong>
        </Typography>
      </Grid>
      <Grid item lg={6} md={2} sm={2} xs={2}>
        <CircularProgress goalPercent={props.goalPercent} />
      </Grid>
      <Grid item lg={6} md={10} sm={10} xs={10}>
        <Stack spacing={{ lg: 3, md: 0, sm: 0, xs: 0 }} alignItems={"flex-end"}>
          <Typography sx={styles.cardBodyText}>
            Goal: {props.goal} Goalprize customer visits before
          </Typography>
          <Typography sx={styles.cardBodyText}>
            the End: {props.endDate}*
          </Typography>
          <Typography sx={styles.cardBodyText}>
            Current: {props.currentVisits} visits
          </Typography>
          <Typography sx={styles.cardBodyText}>
            Start: {props.startDate}
          </Typography>
          <Typography
            sx={styles.cardBodyText}
            style={{ textDecoration: "underline" }}
            onClick={props.onClick}
          >
            See the Goalprize information screen
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

const SecondCard = (props) => {
  return (
    <Grid
      container
      direction={"row"}
      style={{
        backgroundColor: "black",
        borderRadius: 10,
        paddingBottom: 10,
        marginTop: 20,
      }}
    >
      <Box style={styles.cardTitileTextBox}>
        <Typography sx={styles.innerCardTitleText}>
          <strong>2. Winners: You can win</strong>
        </Typography>
      </Box>
      <Grid item lg={6} md={2} sm={2} xs={2}>
        <Box sx={styles.yourRankBox}>
          <center>
            <Typography sx={styles.yourRankText}>
              Your
              <br /> Rank
              <br /> Here
            </Typography>
          </center>
        </Box>
      </Grid>
      <Grid item lg={6} md={10} sm={10} xs={10}>
        <Stack spacing={{ lg: 3, md: 0, sm: 0, xs: 0 }} alignItems={"flex-end"}>
          <Typography sx={styles.cardBodyText}>
            If the Goal is hit before the End the
          </Typography>
          <Typography sx={styles.cardBodyText}>
            Goalprize participants ranked in the
          </Typography>
          <Typography sx={styles.cardBodyText}>
            Top {props.winnerPercent}% by points win a prize.*
          </Typography>
          <Typography
            sx={styles.cardBodyText}
            style={{ textDecoration: "underline" }}
            onClick={props.onClick}
          >
            See the Leaderboard
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

const ThirdCard = (props) => {
  return (
    <Grid
      container
      direction={"row"}
      style={{
        backgroundColor: "black",
        borderRadius: 10,
        paddingBottom: 20,
        marginTop: 20,
      }}
    >
      <Box style={styles.cardTitileTextBox}>
        <Typography sx={styles.innerCardTitleText}>
          <strong>3. Prize: Digital gift card for this restaurant</strong>
        </Typography>
      </Box>
      <Grid item lg={6} md={2} sm={2} xs={2}>
        <Box sx={styles.giftCardBox}>
          <Typography sx={styles.giftCardTextRedeemable}>
            Redeemable only at
          </Typography>
          <Typography sx={styles.giftCardTextRestaurantName}>
            Restaurant Name
          </Typography>

          <Box sx={styles.giftCardBottomBox}>
            <Grid container justifyContent={"center"}>
              <Grid item lg={6} md={6} sm={4} xs={4}>
                <Typography sx={styles.giftCardBottomBoxText}>
                  Credit
                </Typography>
                <Typography sx={styles.giftCardBottomBoxText}>X,XXX</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={8} xs={8}>
                <Typography sx={styles.giftCardBottomBoxText}>
                  Expires
                </Typography>
                <Typography sx={styles.giftCardBottomBoxText}>
                  Month DD, YYYY
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={6} md={10} sm={10} xs={10}>
        <Stack
          spacing={{ lg: 3, md: 0, sm: 0, xs: 0.5 }}
          alignItems={"flex-end"}
        >
          <Typography
            sx={styles.cardBodyText}
            style={{ textDecoration: "underline" }}
            onClick={props.onClick}
          >
            See the Goalprize Information Screen
          </Typography>
          <Typography sx={styles.cardBodyText}>
            *Goalprize Terms of Service,
          </Typography>
          <Typography sx={styles.cardBodyText}>
            Details of this Goalprize,
          </Typography>
          <Typography sx={styles.cardBodyText}>
            Frequent Questions + Examples and more...
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

const FourthCard = () => {
  return (
    <Grid
      container
      direction={"row"}
      style={{
        backgroundColor: "black",
        borderRadius: 10,
        marginTop: 20,
      }}
    >
      <Box>
        <Typography
          sx={styles.cardBodyText}
          style={{
            color: "#FFF500",
            margin: 20,
            fontWeight: "bold",
            fontFamily: "Helvetica Neue",
          }}
        >
          If the Goal is not hit before the End no prizes are given out.
        </Typography>
      </Box>
    </Grid>
  );
};

export default function CampaignDetail(props) {
  const { state } = useLocation();
  function clickLeaderboard() {
    navigate("/leaderboardView", { state: state });
  }

  function clickGoalprizeInformation() {
    navigate("/goalprizeInformationView", { state: state });
  }

  const navigate = useNavigate();
  return (
    <>
      <Grid container justifyContent={"center"}>
        <Card>
          <CardHeader
            title={state.trading?.name}
            style={{
              backgroundColor: "black",
            }}
            align="left"
            disableTypography={true}
            sx={styles.cardTitleText}
          />
          <CardMedia
            sx={{
              /* height: {
                lg: 450,
                md: 220,
                sm: 200,
                xs: 110,
              },
              width: { lg: 1090, md: 390, sm: 320, xs: 175 }, */
              height: 0,
              paddingTop: "56.25%", // 16:9,
              marginTop: "30",
            }}
            image={state.trading?.images[0]?.image}
          />
          <CardContent>
            <FirstCard
              onClick={clickGoalprizeInformation}
              goal={state.goal.toLocaleString()}
              endDate={getDateFromTimeStamp(
                state.endTime,
                "MMMM DD, YYYY hh:mm a"
              )}
              currentVisits={state.currentVisits.toLocaleString()}
              startDate={getDateFromTimeStamp(
                state.startTime,
                "MMMM DD, YYYY hh:mm a"
              )}
              goalPercent={parseInt(state.currentStatus)}
            />
            <SecondCard
              onClick={clickLeaderboard}
              winnerPercent={state.winners}
            />
            <ThirdCard onClick={clickGoalprizeInformation} />
            <FourthCard />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

const styles = {
  cardTitleText: {
    fontSize: {
      lg: 25,
      md: 25,
      sm: 28,
      xs: 18,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
  },

  innerCardTitleText: {
    fontSize: {
      lg: 28,
      md: 28,
      sm: 22,
      xs: 12,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
    paddingRight: {
      lg: 2,
      md: 2,
      sm: 2,
      xs: 1,
    },
  },

  cardBodyText: {
    fontSize: {
      lg: 28,
      md: 22,
      sm: 18,
      xs: 10,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
    paddingRight: {
      lg: 2,
      md: 2,
      sm: 2,
      xs: 1.5,
    },
  },
  cardTitileTextBox: {
    backgroundColor: COLOR.GREEN,
    borderRadius: 10,
    justifyContent: "center",
    width: "100%",
    paddingLeft: 10,
    margin: 10,
  },
  yourRankBox: {
    height: { lg: 300, md: 150, sm: 120, xs: 60 },
    width: { lg: 300, md: 150, sm: 120, xs: 60 },
    backgroundColor: COLOR.WHITE,
    marginLeft: 2,
    borderRadius: 2,
  },

  yourRankText: {
    color: COLOR.BLACK,
    fontSize: {
      lg: 44,
      md: 28,
      sm: 22,
      xs: 12,
    },
    fontFamily: "Helvetica Neue",
    paddingTop: {
      lg: 6,
      md: 1,
      sm: 1,
      xs: 0.5,
    },
  },

  giftCardBox: {
    backgroundColor: "#C3DAFF",
    marginLeft: 2,
    borderRadius: 1,
    width: { lg: 500, md: 320, sm: 220, xs: 110 },
    height: { lg: 260, md: 180, sm: 120, xs: 70 },
  },
  giftCardTextRedeemable: {
    color: COLOR.BLACK,
    fontSize: {
      lg: 30,
      md: 28,
      sm: 16,
      xs: 8,
    },
    paddingTop: {
      lg: 4,
      md: 1,
      sm: 1,
      xs: 1,
    },
    marginLeft: {
      lg: 2,
      md: 1,
      sm: 1,
      xs: 1,
    },
    fontFamily: "Helvetica Neue",
  },
  giftCardTextRestaurantName: {
    color: COLOR.BLACK,
    fontSize: {
      lg: 40,
      md: 28,
      sm: 16,
      xs: 8,
    },
    paddingTop: {
      lg: 2,
      md: 1,
      sm: 1,
      xs: 1,
    },
    marginLeft: {
      lg: 2,
      md: 1,
      sm: 1,
      xs: 1,
    },
    fontWeight: "bold",
    fontFamily: "Helvetica Neue",
  },
  giftCardBottomBox: {
    backgroundColor: COLOR.GP_BLUE,
    width: {
      lg: 500,
      md: 320,
      sm: 220,
      xs: 110,
    },
    height: {
      lg: 70,
      md: 60,
      sm: 44,
      xs: 24,
    },
    borderBottomLeftRadius: {
      lg: 5,
      md: 5,
      sm: 5,
      xs: 2,
    },
    borderBottomRightRadius: {
      lg: 5,
      md: 5,
      sm: 5,
      xs: 2,
    },
    marginTop: {
      lg: 5,
      md: 3,
      sm: 2,
      xs: 1,
    },
  },

  giftCardBottomBoxText: {
    color: COLOR.BLACK,
    fontSize: {
      lg: 22,
      md: 18,
      sm: 15,
      xs: 7.5,
    },
    marginLeft: {
      lg: 2,
      md: 1,
      sm: 1,
      xs: 1,
    },
    fontFamily: "Helvetica Neue",
  },
};
