import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import logo from "../assets/img/logo.png";
import { useNavigate } from "react-router-dom";

function NavBar() {
  const navigate = useNavigate();
  function onLogoClick() {
    navigate("/");
  }
  return (
    <>
      <AppBar sx={{ bgcolor: "#FAF9F6" }}>
        <Container maxWidth="l">
          <Toolbar disableGutters>
            <Box
              component="img"
              sx={{
                height: 50,
                width: 200 /* 
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 }, */,
              }}
              src={logo}
              onClick={onLogoClick}
            />
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </>
  );
}
export default NavBar;
