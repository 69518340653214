import * as React from "react";
import RouteList from "../src/routes/index.js";
import NavBar from "../src/components/AppBar";

export default function App() {
  return (
    <>
      <NavBar />
      <RouteList />
    </>
  );
}
