import React, { useState, useEffect, useRef, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Empty from "../assets/img/empty.png";

export default function NoDataCard(props) {
  return (
    <Grid
      container
      direction={{
        xl: "column",
        lg: "column",
        md: "column",
        sm: "column",
        xs: "column",
      }}
      alignItems="center"
      justifyContent="center"
      columnSpacing={2}
      rowSpacing={2}
      sx={{
        marginTop: {
          xl: 2,
          lg: 2,
          md: 0,
          sm: 0,
          xs: 0,
        },
        marginBottom: {
          xl: 4,
          lg: 4,
          md: 0,
          sm: 0,
          xs: 0,
        },
      }}
    >
      <Grid item>
        <CardMedia
          component="img"
          image={Empty}
          sx={{ width: 200, height: 200 }}
        />
      </Grid>
      <Grid item>
        <Typography textAlign={"center"} sx={styles.titleText}>
          {props.title}
        </Typography>
      </Grid>
    </Grid>
  );
}
const styles = {
  titleText: {
    fontSize: {
      lg: 32,
      md: 32,
      sm: 32,
      xs: 24,
    },
    fontFamily: "Helvetica Neue",
    color: "black",
  },
};
