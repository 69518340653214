import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import COLOR from "../assets/js/Color";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import FlatList from "flatlist-react";
import Card from "@mui/material/Card";
import { handlingGPNumber } from "../commonView/Helpers";
import io from "socket.io-client";
import { U_SOCKET_URL, U_SOCKET_EVENT_NAME } from "../commonView/Constants";
import Loader from "../components/Loader";
import NoDataCard from "../components/NoDataCard";

const socket = io();
export default function LeaderboardView() {
  const { state } = useLocation();
  const [leaderBoardData, setLeaderBoardData] = useState(null);
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    const socket = io(U_SOCKET_URL, {
      transports: ["websocket"],
    });

    socket.on("connect", (data) => {
      setIsConnected(true);
    });

    // receive data from the server
    socket.on(U_SOCKET_EVENT_NAME, (data) => {
      setLeaderBoardData(data);
    });

    socket.emit(U_SOCKET_EVENT_NAME, { id: state.id }, (data) => {});
  }, []);

  const RenderWhenEmpty = () => {
    if (leaderBoardData?.leaderboard.length === 0) {
      return <NoDataCard title="No Leaderboard Data!" />;
    }
    return <Loader visible={true} />;
  };

  const FirstCard = (item) => {
    const index = leaderBoardData?.leaderboard.indexOf(item);
    function getWinnerIndex(data) {
      if (data) {
        if (data?.leaderboard.length == 0) {
          let percentOfWinner = leaderBoardData
            ? leaderBoardData?.winners
            : null;
          let winnerCount = percentOfWinner;
          return Math.ceil(winnerCount);
        }
        if (!state) {
          return 0;
        } else if (state && state?.isFinished == 0) {
          if (state.endTime < parseInt(new Date().getTime() / 1000)) {
            return 0;
          } else {
            let winnerCount = (data?.leaderboard?.length * data?.winners) / 100;
            return Math.ceil(winnerCount);
          }
        } else {
          let winnerCount = (data?.leaderboard.length * data?.winners) / 100;
          return Math.ceil(winnerCount);
        }
      } else {
        return 0;
      }
    }

    return (
      <Grid container justifyContent={"center"}>
        <Grid
          container
          sx={{
            backgroundColor:
              getWinnerIndex(leaderBoardData) != 0
                ? index < getWinnerIndex(leaderBoardData)
                  ? COLOR.GREEN
                  : COLOR.GP_BLUE
                : COLOR.GP_BLUE,
            marginBottom: {
              xl: 1,
              lg: 1,
              md: 1,
              sm: 1,
              xs: 0.5,
            },
            minHeight: {
              xl: 70,
              lg: 70,
              md: 70,
              sm: 50,
              xs: 50,
            },
            paddingTop: {
              xl: 1,
              lg: 1,
              md: 2,
              sm: 1,
              xs: 2,
            },
          }}
          key={item}
        >
          <Grid xl={4} lg={4} md={4} sm={4} xs={4} item>
            <Typography textAlign={"center"} sx={styles.cardBodyText}>
              {index + 1}
            </Typography>
          </Grid>
          <Grid xl={4} lg={4} md={4} sm={4} xs={4} item>
            <Typography textAlign={"center"} sx={styles.cardBodyText}>
              {handlingGPNumber(item.bbb_id)}
            </Typography>
          </Grid>
          <Grid xl={4} lg={4} md={4} sm={4} xs={4} item>
            <Typography textAlign={"center"} sx={styles.cardBodyText}>
              {item.totalCredits}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Card
          sx={{
            width: {
              xl: "60%",
              lg: "60%",
              md: "90%",
              sm: "90%",
              xs: "100%",
            },
          }}
        >
          <Grid
            container
            sx={{
              color: COLOR.WHITE,
              backgroundColor: COLOR.GP_BLUE,
              marginBottom: 0.2,
              minHeight: {
                xl: 70,
                lg: 70,
                md: 70,
                sm: 50,
                xs: 50,
              },
              paddingTop: {
                xl: 1,
                lg: 1,
                md: 2,
                sm: 1,
                xs: 2,
              },
            }}
          >
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <Typography sx={styles.innerCardTitleText} textAlign={"center"}>
                Rank
              </Typography>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <Typography sx={styles.innerCardTitleText} textAlign={"center"}>
                Top Spenders
              </Typography>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <Typography sx={styles.innerCardTitleText} textAlign={"center"}>
                Current Points
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <FlatList
              list={leaderBoardData?.leaderboard}
              renderItem={FirstCard}
              renderWhenEmpty={RenderWhenEmpty}
            />
          </Grid>
        </Card>
      </Grid>
    </>
  );
}

const styles = {
  innerCardTitleText: {
    fontSize: {
      xl: 28,
      lg: 28,
      md: 24,
      sm: 22,
      xs: 14,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
    fontWeight: "bold",
  },

  cardBodyText: {
    fontSize: {
      xl: 28,
      lg: 22,
      md: 24,
      sm: 22,
      xs: 14,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
  },
};
