import moment from "moment";

export const getDateFromTimeStamp = (val, format) => {
  // let appLang = auth.getValue(AS_APP_LANGUAGE);
  if (val) {
    if (String(val).length > 10) {
      let time = moment(new Date(val ? val : Date.now())).format(format);
      return time;
    } else {
      let time = moment(new Date(val ? val * 1000 : Date.now())).format(format);
      return time;
    }
  } else {
    return null;
  }
};

export const handlingGPNumber = (number) => {
  let pattern = number
    .replace(/\s?/g, "")
    .replace(/(\d{3})/g, "$1-")
    .trim();
  return pattern.slice(0, -1);
};
