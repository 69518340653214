import { ThreeDots } from "react-loader-spinner";
import Grid from "@mui/material/Grid";
import COLOR from "../assets/js/Color";

export default function Loader(props) {
  return (
    <Grid
      container
      direction={"row"}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <ThreeDots
          height="150"
          width="150"
          radius="9"
          color={COLOR.GP_BLUE}
          ariaLabel="three-dots-loading"
          visible={props.visible}
        />
      </Grid>
    </Grid>
  );
}
