import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import COLOR from "../assets/js/Color";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function CircularProgress(props) {
  return (
    <Box
      sx={{
        width: { lg: 300, md: 200, sm: 150, xs: 70 },
        height: { lg: 300, md: 200, sm: 150, xs: 70 },
        marginLeft: 2,
      }}
    >
      <CircularProgressbarWithChildren
        value={props.goalPercent}
        styles={buildStyles({
          pathColor: COLOR.GREEN,
          trailColor: COLOR.LIGHT_GREY,
          backgroundColor: COLOR.WHITE,
        })}
        background={true}
        strokeWidth={15}
      >
        {/* <div
          style={{
            fontSize: 44,
            marginTop: -5,
            fontFamily: "Helvetica Neue",
          }}
        >
          <strong>Goal</strong>
        </div> */}
        <Typography
          sx={{
            fontSize: {
              lg: 44,
              md: 28,
              sm: 30,
              xs: 15,
            },
            fontFamily: "Helvetica Neue",
            color: COLOR.GP_BLUE,
          }}
        >
          Goal
        </Typography>
        <Typography
          sx={{
            fontSize: {
              lg: 44,
              md: 28,
              sm: 30,
              xs: 15,
            },
            fontFamily: "Helvetica Neue",
            color: COLOR.GP_BLUE,
          }}
        >
          {props.goalPercent}%
        </Typography>
        {/* <div
          style={{
            fontSize: 44,
            marginTop: 5,
            fontFamily: "Helvetica Neue",
          }}
        >
          <strong>66%</strong>
        </div> */}
      </CircularProgressbarWithChildren>
    </Box>
  );
}
